var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.isInfoLoaded && _vm.canManageRelease ? _c('div', [_vm.getInactiveUserMessage ? _c('div', [_c('no-data', {
    attrs: {
      "first-text": _vm.getInactiveUserMessage
    }
  })], 1) : _vm.getInfoErrorMessage ? _c('div', [_c('no-data', {
    attrs: {
      "first-text": _vm.getInfoErrorMessage,
      "with-button": ""
    }
  })], 1) : _c('div', [_c('v-tabs', {
    staticClass: "hide-border-bottom",
    attrs: {
      "vertical": ""
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('v-tab', [_c('span', {
    class: {
      'darkGrey--text': _vm.$vuetify.theme.dark
    }
  }, [_vm._v("Releases")])]), _c('v-tab', [_c('span', {
    class: {
      'darkGrey--text': _vm.$vuetify.theme.dark
    }
  }, [_vm._v("Sessions (SDK) "), _c('v-chip', {
    attrs: {
      "x-small": "",
      "color": "warning",
      "label": "",
      "outlined": ""
    }
  }, [_vm._v("BETA")])], 1)]), _c('v-tab', [_c('span', {
    class: {
      'darkGrey--text': _vm.$vuetify.theme.dark
    }
  }, [_vm._v("Apps "), _c('v-chip', {
    attrs: {
      "x-small": "",
      "color": "success",
      "label": "",
      "outlined": ""
    }
  }, [_vm._v("NEW")])], 1)]), _c('v-tab-item', {
    staticClass: "my-2"
  }, [_c('releases', {
    attrs: {
      "app_id": _vm.$route.params.app_id
    }
  })], 1), _c('v-tab-item', {
    staticClass: "my-2"
  }, [_c('SDK', {
    attrs: {
      "from-sdk-page": "",
      "app_id": _vm.$route.params.app_id
    }
  })], 1), _c('v-tab-item', {
    staticClass: "my-2"
  }, [_c('App', {
    attrs: {
      "app_id": _vm.$route.params.app_id
    }
  })], 1)], 1)], 1)]) : _c('div', [_c('no-data', {
    attrs: {
      "first-text": "No integrations to show",
      "second-text": "It seems that you don't have the permission to view the app integrations."
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }