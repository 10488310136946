<template>
  <div>
    <div v-if="!isInfoLoaded && canManageRelease">
      <div v-if="getInactiveUserMessage">
        <no-data :first-text="getInactiveUserMessage"></no-data>
      </div>
      <div v-else-if="getInfoErrorMessage">
        <no-data :first-text="getInfoErrorMessage" with-button> </no-data>
      </div>
      <div v-else>
        <v-tabs class="hide-border-bottom" vertical v-model="activeTab">
          <v-tab
            ><span :class="{ 'darkGrey--text': $vuetify.theme.dark }"
              >Releases</span
            ></v-tab
          >
          <v-tab
            ><span :class="{ 'darkGrey--text': $vuetify.theme.dark }"
              >Sessions (SDK)
              <v-chip x-small color="warning" label outlined>BETA</v-chip></span
            ></v-tab
          >
          <v-tab
            ><span :class="{ 'darkGrey--text': $vuetify.theme.dark }"
              >Apps
              <v-chip x-small color="success" label outlined>NEW</v-chip></span
            ></v-tab
          >
          <v-tab-item class="my-2">
            <releases :app_id="$route.params.app_id" />
          </v-tab-item>
          <v-tab-item class="my-2">
            <SDK from-sdk-page :app_id="$route.params.app_id" />
          </v-tab-item>
          <v-tab-item class="my-2">
            <App :app_id="$route.params.app_id" />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
    <div v-else>
      <no-data
        first-text="No integrations to show"
        second-text="It seems that you don't have the permission to view the app integrations."
      ></no-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    tab: {
      type: String,
      default: "releases",
    },
  },
  data() {
    return {
      activeTab: null,
      locked: true,
    };
  },
  watch: {
    $route(to) {
      this.setActiveTabFromRoute(to);
    },
    activeTab: "updateRoute",
  },
  components: {
    Releases: () => import("@/view/components/App/Integrations/Releases.vue"),
    SDK: () => import("@/view/components/Sessions/GetStarted.vue"),
    App: () => import("@/view/components/App/Integrations/App.vue"),
  },
  computed: {
    ...mapGetters({
      getInfoErrorMessage: "getInfoErrorMessage",
      getInactiveUserMessage: "getInactiveUserMessage",
    }),
  },
  created() {
    this.setActiveTabFromRoute(this.$route);
    if (!this.$route.params.app_id) {
      this.activeTab = 2;
    }
  },
  methods: {
    setActiveTabFromRoute(route) {
      const tab = route.params.tab || "releases"; // Default to 'releases' if no tab is provided
      const tabIndex = this.getTabIndex(tab);
      if (tabIndex !== -1) {
        this.activeTab = tabIndex;
      }
    },
    getTabIndex(tabName) {
      switch (tabName) {
        case "releases":
          return 0;
        case "sessions":
          return 1;
        case "apps":
          return 2;
        default:
          return -1;
      }
    },
    updateRoute() {
      const tabName = this.getTabName(this.activeTab);
      this.$router.push({
        params: { tab: tabName },
      });
    },
    getTabName(index) {
      switch (index) {
        case 0:
          return "releases";
        case 1:
          return "sessions";
        case 2:
          return "apps";
        default:
          return "";
      }
    },
  },
};
</script>
<style>
.hide-border-bottom .v-tabs-bar__content:not(.small-tabs) {
  border-bottom: none !important;
}
.hide-border-bottom .v-tab {
  text-align: left !important;
  justify-content: left !important;
}
</style>
